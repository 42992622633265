











import {Goods} from "@/request/goods/Goods";
import {Category} from "@/request/goods/Category";
import AddGoodsStore from './AddGoodsStore';

export default {
    data() {
        let self: any =this;
        return {
            modal: Goods,
            search: [
                {
                    type: 'Input',
                    label: '商品名称',
                    key: 'name',
                    clearable: true,
                    width: '160px',
                    placeholder: '请填写商品名称'
                },
                {
                    type: 'Input',
                    label: '商品编码',
                    key: 'code',
                    clearable: true,
                    width: '160px',
                    placeholder: '请填写商品编码'
                },
                {
                    type: 'Select',
                    label: '状态',
                    key: 'status',
                    clearable: true,
                    width: '120px',
                    items: [
                        {
                            label: '下线',
                            value: 0
                        },
                        {
                            label: '上线',
                            value: 1
                        },
                        {
                            label: '等待上线',
                            value: 2
                        }
                    ]
                },
                {
                    type: 'Select',
                    label: '一级分类',
                    key: 'categoryFirstId',
                    clearable: true,
                    width: '120px',
                    items() {
                        return new Promise(resolve => {
                            Category.getCategoryList().then((body: any) => {
                                let data: any = body.data;
                                resolve(data.map((j: any) => {
                                    return {
                                        label: j.name,
                                        value: j.id
                                    }
                                }));
                            })
                        })
                    }
                },
                {
                    type: 'DatePicker',
                    pickerType: 'datetimerange',
                    format: 'yyyy-MM-dd HH:mm',
                    label: '日期',
                    width: '220px',
                    placeholder: '创建时间'
                }
            ],
            columns: [
                {
                    title: '商品名称',
                    key: 'name',
                    minWidth: 200,
                    tooltip: true
                },
                {
                    title: '定时上线',
                    key: 'timing',
                    minWidth: 150,
                    render: (h: any, p: any) => h('p', p.row.status===2? p.row['timing']: '-')
                },
                {
                    title: '商品编号',
                    key: 'code',
                    minWidth: 120
                },
                {
                    title: '一级分类',
                    key: 'categoryFirst',
                    minWidth: 120
                },
                {
                    title: '二级分类',
                    key: 'categorySec',
                    minWidth: 120
                },
                {
                    title: '库存数量',
                    key: 'num',
                    minWidth: 120
                },
                {
                    title: '销售数量',
                    key: 'saleNum',
                    minWidth: 120
                },
                {
                    title: '最低售价',
                    key: 'lowPrice',
                    minWidth: 120,
                    render: (h: any, p: any) => h('p', (p.row.lowPrice / 100).toFixed(2))
                },
                {
                    title: '自定义标签',
                    key: 'flags',
                    minWidth: 140,
                    tooltip: true,
                    render: (h: any, p: any) => {
                        let flags: any = p.row.flags;
                        let els: any = [];
                        flags.forEach((j: any) => {
                            els.push(h('span', {
                                style: {
                                    backgroundColor: 'red',
                                    // fontSize: '12px',
                                    color: '#ffffff',
                                    padding: '2px 5px',
                                    borderRadius: '3px',
                                    margin: '3px',
                                    fontSize: '10px'
                                }
                            }, j));
                        });
                        return h('div', els);
                    }
                },
                {
                    title: '参与活动',
                    key: 'activityType',
                    minWidth: 120,
                    render: (h: any, p: any) => {
                        let d: string = '';
                        let activityType: number = p.row.activityType;
                        switch (activityType) {
                            case 0:
                                d = '无';
                                break;
                            case 1:
                                d = '导购';
                                break;
                            case 2:
                                d = '满折';
                                break;
                            case 3:
                                d = '满减';
                                break;
                            case 4:
                                d = '会员价';
                                break;
                            case 5:
                                d = '秒杀';
                                break;
                            case 6:
                                d = '分销';
                                break;
                            case 7:
                                d = '普通团购';
                                break;
                            case 8:
                                d = '社区团购';
                                break;
                        }
                        return h('p', d);
                    }
                },
                {
                    title: '创建时间',
                    key: 'createTime',
                    minWidth: 180
                }
            ]
        }
    },
    activated() {
        let self: any = this;
        if (AddGoodsStore.getters.goods === undefined) {
            self.$refs['el-table']?.flush();
        }
    },
    methods: {
        edit(row: any) {
            let self: any = this;
            let params = self.assignObject(row);
            params.standards.forEach((j: any) => {
                j.secStandards.forEach((k: any) => {
                    k.price = (k.price / 100);
                    if (k.reducedPrice)
                        k.reducedPrice = (k.reducedPrice / 100);
                    if (k.inPrice)
                        k.inPrice = (k.inPrice / 100);
                })
            });
            AddGoodsStore.commit('goods', params);
            self.$parent.$parent.$parent.$parent.$refs['menu'].$emit('on-select', 'goods_add_goods')
        }
    }
}
