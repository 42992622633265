import {BaseRequest} from "@/request/BaseRequest";

class Category extends BaseRequest {

    public requestPath: string = '/goods/category';

    getCategoryList(): any {
        return this.listWithUrl(`${this.requestPath}/firstAll`);
    }

    getCategorySecList(categoryId: string): any {
        return this.listWithUrl(`${this.requestPath}/secondAll`, {id: categoryId});
    }

    saveCategory(data: any): any {
        return this.saveWithUrl(`${this.requestPath}/firstSaveOrUpdate`, data);
    }

    saveCategorySec(data: any): any {
        return this.saveWithUrl(`${this.requestPath}/secondSaveOrUpdate`, data);
    }

    delCategoryByIds(id: Array<string>): any {
        return this.delWithUrl(`${this.requestPath}/firstDel`, id);
    }

    delCategorySec(data: {id: string, secId: string}): any {
        return this.request({
            url: `${this.requestPath}/secondDel`,
            method: 'delete',
            data
        })
    }

}

const c = new Category();
export {c as Category};