import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex);

const store = new Vuex.Store({
    state: {
        goods: undefined
    },
    getters: {
        goods: state => state.goods
    },
    mutations: {
        goods: ((state, payload) => state.goods=payload)
    },
    actions: {
    }
});

export default store;
