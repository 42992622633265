import {BaseRequest} from "@/request/BaseRequest";

class Goods extends BaseRequest {

    public requestPath: string = '/goods/goods';

    /**
     * 商品规格列表
     * @param type
     */
    standardList(type: number = 0): any {
        return this.listWithUrl(`${this.requestPath}/standardList`, {type: type})
    }

    /**
     * 通过商品名称搜索
     * @param name
     */
    summaryList(name: string): any {
        return this.listWithUrl(`${this.requestPath}/summaryList`, {name: name})
    }

}

const c = new Goods();
export {c as Goods};